<template>
  <button v-bind="$attrs" :type="type" class="icon-button btn btn-icon" :class="classes">
    <icon class="text-sm default-icon" :icon="icon" />
    <icon class="text-sm hover-icon" :icon="hoverIcon || icon" />
  </button>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { computed, PropType } from "vue";

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  hoverIcon: {
    type: String,
    default: null,
  },
  size: {
    type: String as PropType<"small" | "medium">,
    default: "small",
  },
  buttonStyle: {
    type: String as PropType<"default" | "low-contrast">,
    default: "default",
  },
  type: {
    type: String as PropType<"button" | "submit" | "reset">,
    default: "button",
  },
});

const classes = computed(() => {
  return {
    "btn-xs": props.size === "small",
    "btn-primary": props.size === "small" && props.buttonStyle === "default",
    "btn-low-contrast": props.size === "small" && props.buttonStyle === "low-contrast",
    "btn-dark": props.size === "medium",
  };
});
</script>

<style lang="scss">
.icon-button {
  &.btn-low-contrast {
    &,
    &:focus {
      background-color: var(--color-interactive-fill-neutral-low-contrast);
      color: var(--color-text-variant);
    }

    &:hover {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary);
        color: var(--color-text-button-primary);
      }
    }
  }

  .hover-icon {
    display: none;
  }

  &:hover {
    .default-icon {
      display: none;
    }

    .hover-icon {
      display: block;
    }
  }
}
.dark-bg {
  .icon-button.btn-xs.btn-primary {
    &,
    &:focus {
      background-color: var(--color-interactive-fill-neutral-high-contrast);
      border-color: var(--color-interactive-fill-neutral-high-contrast);
      color: var(--color-text-primary-inverse);
    }

    &:hover {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary-on-dark);
        border-color: var(--color-interactive-fill-primary-on-dark);
        color: var(--color-button-primary-hover-text);
      }
    }

    &:active,
    &.active {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary-variant-on-dark);
        border-color: var(--color-interactive-fill-primary-variant-on-dark);
        color: var(--color-button-primary-active-text);
      }
    }

    &:disabled,
    &.disabled {
      &,
      &:hover,
      &:focus {
        background-color: var(--color-button-primary-disabled-background);
        border-color: var(--color-button-primary-disabled-background);
        color: var(--color-button-primary-disabled-text);
        opacity: 1;
      }
    }
  }
}
</style>
