<template>
  <div class="dashboard-templates-list">
    <div class="pull-right">
      <dropdown>
        <template #toggle="toggleProps">
          <div class="btn-group">
            <button class="btn btn-primary" type="button" @click="newDashboardTemplate()">
              {{ $t("dashboard_templates.new_dashboard_template") }}
            </button>
            <button class="btn btn-primary" type="button" @click="toggleProps.toggleDropdown()">
              <span class="caret"></span>
            </button>
          </div>
        </template>
        <template #menu>
          <dropdown-item @click="newDashboardTemplate()">{{ $t("dashboard_templates.empty_template") }}</dropdown-item>
          <dropdown-item @click="newTemplateFromDashboard()">{{
            $t("dashboard_templates.from_dashboard")
          }}</dropdown-item>
        </template>
      </dropdown>
    </div>
    <div class="filters">
      <span v-if="partnerFilter" class="badge"
        >{{ partnerFilter.name }} <i class="fa fa-times" @click="partnerFilter = null"></i
      ></span>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>{{ $t("attributes.name") }}</th>
          <th>{{ $t("attributes.visibility") }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="template in filteredTemplates" :key="template.id">
          <td>{{ template.name }}</td>
          <td>
            <a v-for="site in visibleSites(template)" :key="site.id" class="site" @click.stop="setFilter(site)">{{
              site.name
            }}</a>
          </td>
          <td class="actions">
            <div class="action">
              <a @click="editTemplate(template)">{{ $t("actions.edit") }}</a>
            </div>
            <div class="action">
              <a @click.stop="duplicateTemplate(template)">{{ $t("actions.duplicate") }}</a>
            </div>
            <div class="action">
              <confirm-button @confirm="deleteTemplate(template)"
                ><a>{{ $t("actions.delete") }}</a></confirm-button
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <dashboard-template-from-dashboard-modal
      ref="dashboardTemplateFromDashboardModal"
      @select="onSelectDashboard"
    ></dashboard-template-from-dashboard-modal>
  </div>
</template>

<script lang="ts">
import { makeApiInstance } from "../../api/instance";
import AnalyticsTemplate from "../../model/analytics-template";
import DashboardTemplateFromDashboardModal from "./dashboard-template-from-dashboard-modal.vue";
import { defineComponent } from "vue";
import dropdown from "../../components/dropdown.vue";
import Partner from "../../model/partner";
import confirmButton from "../../components/confirm-button.vue";
import DropdownItem from "../../components/dropdown-item.vue";

export default defineComponent({
  components: {
    DashboardTemplateFromDashboardModal,
    dropdown,
    confirmButton,
    DropdownItem,
  },
  data() {
    const partnerFilter: Partner = null;
    const templates: AnalyticsTemplate[] = null;

    return {
      templates,
      partnerFilter,
    };
  },
  computed: {
    api() {
      return makeApiInstance({ baseURL: "/api/zoined_admin/analytics_templates/" });
    },
    filteredTemplates(): AnalyticsTemplate[] {
      if (!this.partnerFilter) {
        return this.templates;
      }

      return this.templates.filter((template) =>
        this.partnerFilter.id === "zoined"
          ? template.visible_in_zoined
          : template.partners.some(({ id }) => id === this.partnerFilter.id)
      );
    },
  },
  created() {
    this.fetchDashboardTemplates();
  },
  methods: {
    setFilter(filter: Partner) {
      this.partnerFilter = filter;
    },
    visibleSites(template: AnalyticsTemplate): Partner[] {
      const sites = [];
      if (template.visible_in_zoined) {
        sites.push({ id: "zoined", name: "Zoined" });
      }
      template.partners.forEach(({ id, name }) => sites.push({ id, name }));
      return sites;
    },
    newDashboardTemplate() {
      this.$router.push({ name: "new_dashboard_template" });
    },
    newTemplateFromDashboard() {
      (this.$refs.dashboardTemplateFromDashboardModal as typeof DashboardTemplateFromDashboardModal).show();
    },
    onSelectDashboard(id) {
      this.$router.push({ name: "new_dashboard_template", query: { dashboardId: id } });
    },
    editTemplate(template: AnalyticsTemplate) {
      this.$router.push({ name: "edit_dashboard_template", params: { id: template.id.toString() } });
    },
    duplicateTemplate(template: AnalyticsTemplate) {
      this.$router.push({ name: "new_dashboard_template", query: { templateId: template.id.toString() } });
    },
    async deleteTemplate(template: AnalyticsTemplate) {
      await this.api.delete(template.id.toString());
      this.templates = this.templates.filter((it) => it !== template);
    },
    async fetchDashboardTemplates() {
      const result = await this.api.get("", { params: { analytics_type: "dashboard" } });
      this.templates = result.data;
    },
  },
});
</script>

<style lang="scss" scoped>
a.site {
  cursor: pointer;
  margin-right: 5px;
}
.actions {
  .action {
    display: inline-block;
    margin: 0 10px;
    a {
      cursor: pointer;
    }
    a:not(:hover) {
      color: gray;
    }
  }
}
</style>
