<template>
  <span>
    <dropdown>
      <template #toggle="toggleProps">
        <button class="btn btn-primary mr-md" type="button" @click="toggleProps.toggleDropdown()">
          <span class="mr-md">{{ $t("newsletter.definition.deliver_all") }}</span
          ><span class="caret"></span>
        </button>
      </template>
      <template #menu>
        <dropdown-item v-for="frequency in frequencies" :key="frequency" @click="confirmMassSend(frequency)"
          ><span
            >{{ $t("newsletter.definition.send_now") }}: {{ $t("newsletter.frequency." + frequency) }}</span
          ></dropdown-item
        >
      </template>
    </dropdown></span
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dropdown from "../components/dropdown.vue";
import i18n from "../i18n";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import toastr from "toastr";
import DropdownItem from "../components/dropdown-item.vue";
import { MessageBox } from "uiv";

export default defineComponent({
  components: {
    dropdown,
    DropdownItem,
  },
  computed: {
    frequencies() {
      return ["daily", "weekly", "monthly", "quarterly", "yearly"];
    },
    companyId() {
      return window.zoinedContext.companyId;
    },
  },
  methods: {
    massSendByFrequency(frequency) {
      return newsletterDefinitionsApi
        .deliver({ company_id: this.companyId, id: frequency })
        .then(({ delivery_count }) => {
          toastr.success(i18n.t("newsletter.definition.delivered_successfully_message", { delivery_count }));
        });
    },
    confirmMassSend(frequencyId) {
      const frequency = i18n.t("newsletter.frequency." + frequencyId).toLowerCase();
      const content = i18n.t("newsletter.definition.deliver_all_confirmation_message", { frequency });

      return MessageBox.confirm({
        content,
        okText: i18n.t("actions.confirm"),
        cancelText: i18n.t("actions.cancel"),
      })
        .then(() => this.massSendByFrequency(frequencyId))
        .catch((err) => {
          if (err !== "cancel") {
            throw err;
          }
        });
    },
  },
});
</script>
