<template>
  <div class="input-group">
    <span class="input-group-addon"> <div class="fa fa-search"></div></span>
    <input
      id="search"
      class="form-control"
      type="text"
      :value="modelValue"
      :placeholder="$t('actions.search')"
      @input="onInput"
    />
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      onInput: _.debounce((event) => {
        this.$emit("update:modelValue", event.target.value);
      }, 400),
    };
  },
});
</script>
